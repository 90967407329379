import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Observable, throwError } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';

import { AuthService } from '../services/auth.service';
import { SpinnerOverlayService } from '../services/spinner-overlay.service';

@Injectable()
export class CustomInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private loadingBar: SpinnerOverlayService,
    private router: Router,
  ) {
  }


  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    const token: string = localStorage.getItem('auth-token') || null;
    if (token) {
      request = request.clone({
        headers: request.headers.set('Authorization', 'Bearer ' + token),
      });
    }

    if(token && this.authService.getUserInfoAuth().PaymentId == 0)
    {
      this.redirectToSubscription();
    }

    this.loadingBar.setLoading(true, request.url);

    return next.handle(request).pipe(finalize(() => {
        this.loadingBar.setLoading(false, request.url);
      }),
      map((event: HttpEvent<any>) => {
        return event;
      }),
      catchError((err: any) => {
        if (err.status === 401) {
          this.handle401Error(request, next, err);
        }
        return throwError(err);
      }),
    );
  }

  handle401Error(
    req: HttpRequest<any>,
    next: HttpHandler,
    err: HttpErrorResponse,
  ) {
    return this.logoutUser('');
  }

  logoutUser(error) {
    this.authService.logout();
    this.router.navigate(['/login']);
  }

  redirectToSubscription()
  {
   this.router.navigate([`/auth/${this.authService.getCurrentUserRoleName()}/settings`], {
                  state: { fromDashboard: true, tab: 'subscription' },
                });
  }
}
