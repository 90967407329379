export class ApiConstants {
  static readonly registration = 'public/api/registration';
  static readonly logout = 'public/api/logout';
  static readonly changePassword =
    'private/api/account-settings/change-password';
  static readonly getUserByEmailId = 'private/api/dashboard/getUserByEmailId';
  static readonly getUserByUserId = 'private/api/dashboard/getUserByUserId';
  static readonly login = 'public/api/login';
  static readonly emailVerify = 'public/api/email-verify';
  static readonly getAccountSetting = 'private/api/account-settings/get';
  static readonly updateAccountSetting = 'private/api/account-settings/update';
  static readonly fileUpload = 'private/api/file/add';
  static readonly getFuneralHomeProfile = 'private/api/funeral-home-profile/get';
  static readonly upadteFuneralHomeProfile = 'private/api/funeral-home-profile/update';
  static readonly deleteMediaFile = 'private/api/file/delete';
  static readonly addJob = 'private/api/job/add';
  static readonly deleteJob = 'private/api/job/delete';
  static readonly setDeletedJob = 'private/api/job/setDeletedJob';
  static readonly getJobById = 'private/api/job/getJobById';
  static readonly updateJob = 'private/api/job/update';
  static readonly updateExternalJob = 'private/api/job/updateExternal';
  static readonly updatePayment = 'private/api/job/payment';
  static readonly getBilling = 'private/api/job/billing';
  static readonly getJobCount = 'private/api/job/jobsCount';
  static readonly getCompany = 'private/api/company/get';
  static readonly getCompanyAcceptedProfiles = 'public/api/public/company/get-company-accepted-profiles';
  static readonly getDecypheredKey = 'public/api/public/decypherKey';
  static readonly getPublicCompany = 'public/api/company/get';
  static readonly getJob = 'private/api/job/get';
  static readonly updateJobStep1 = 'private/api/job/update/step-1';
  static readonly publishJob = 'private/api/job/update/publish';
  static readonly getPublicJob = 'public/api/job/get';
  static readonly getJobByStatus = 'private/api/job/getJobsByStatusId';
  static readonly getJobsByStatusIdAndUserId = 'private/api/job/getJobsByStatusIdAndUserId';
  static readonly getEmployerPublishedJobs = 'private/api/job/getEmployerPublishedJobs';
  static readonly getDeletedJobs = 'private/api/job/getDeletedJobs';
  static readonly deleteJobById = 'private/api/job/deleteJobById';
  static readonly setDeletedJobById = 'private/api/job/setDeletedJobById';
  static readonly getJobsByUserId = 'private/api/job/getJobsByUserId';
  static readonly storeRatingByUserId = 'private/api/job/storeRating';
  static readonly getSystemNotification = 'private/api/notification/get';
  static readonly setSystemNotificationAsRead = 'private/api/notification/setNotificationAsRead';
  static readonly getCandidateProfile = 'private/api/candidate/profile';
  static readonly getProfessionTranslation = 'private/api/candidate/translateProfession';
  static readonly getSkillTranslation = 'private/api/candidate/translateSkill';
  static readonly getIndustryTranslation = 'private/api/candidate/translateIndustry';
  static readonly getEmployerCandidateProfile = 'private/api/employer/candidate/candidate-profile';
  static readonly getPublicCandidateProfile = 'public/api/public/candidate/profile';
  static readonly updateCandidatePersonalInfo = 'private/api/candidate/update/personal-info';
  static readonly updateCandidatePersonalInfoStep1 = 'private/api/candidate/update/step-1';
  static readonly updateCandidatePersonalInfoStep2 = 'private/api/candidate/update/step-2';
  static readonly updateCandidatePersonalInfoStep4 = 'private/api/candidate/update/step-4';
  static readonly updateCandidatePersonalInfoStep5 = 'private/api/candidate/update/step-5';
  static readonly updateCandidatePersonalInfoStep6 = 'private/api/candidate/update/step-6';
  static readonly getMediaFile = 'private/api/file/get';
  static readonly getMediaFileByType = 'private/api/file/getByType';
  static readonly getExternalJobs = 'public/api/getExternalJobs';
  static readonly getCandidateAppliedJobsByJobId = 'private/api/job/getCandidateAppliedJobsByJobId';
  static readonly getCandidateAppliedJobs = 'private/api/candidate/getCandidateAppliedJobsByJobId';
  static readonly getCandidateAppliedJobsByFilters = 'private/api/candidate/getCandidateAppliedJobsByFilters';
  static readonly pauseJobs = 'private/api/job/jobPause';
  static readonly playJobs = 'private/api/job/jobPlay';
  static readonly sendEmail = 'public/api/forgotPassword/sendEmail';
  static readonly verifyHash = 'public/api/forgotPassword/verifyHash';
  static readonly resetPassword = 'public/api/forgotPassword/resetPassword';
  static readonly createSupport = 'private/api/job/createSupportTicket';
  static readonly createSupportForJobRecovery = 'private/api/job/createSupportTicketForJobRecovery';
  static readonly createSupportForPaymentRecovery = 'private/api/job/createSupportTicketForPaymentRecovery';
  static readonly getSupportTicket = 'private/api/job/getSupportTicket';
  static readonly getAllSupportTicket = 'private/api/job/getAllSupportTicket';
  static readonly getSupportTicketCount = 'private/api/job/getSupportTicketCount';
  static readonly getAllSupportTicketCount = 'private/api/job/getAllSupportTicketCount';
  static readonly getAdminSupportTicket = 'private/api/admin/getSupportTicket';
  static readonly updateLanguageData = 'private/api/admin/updateLanguageData';
  static readonly updateProfession = 'private/api/admin/updateProfession';
  static readonly deleteLanguageData = 'private/api/admin/deleteLanguageData';
  static readonly deleteProfession = 'private/api/admin/deleteProfession';
  static readonly setAdminSalesStatus = 'private/api/admin/changeSalesStatus';
  static readonly getAdminSupportTicketCount = 'private/api/admin/getSupportTicketCount';
  static readonly sendAccidentEmail = 'private/api/admin/sendAccidentEmail';
  static readonly sendCampaignMail = 'private/api/job/sendCampaignMail';
  static readonly getAdminJobSupportTicket = 'private/api/admin/getJobSupportTicket';
  static readonly updateAdminSupportTicketStatus = 'private/api/admin/updateSupportTicketStatus';
  static readonly updateSupportTicketStatus = 'private/api/job/updateSupportTicketStatus';
  static readonly deleteSupportTicket = 'private/api/admin/deleteSupportTicket';
  static readonly getTransactionById = 'private/api/job/getTransactionById';
  static readonly getSessionDetails = 'private/api/payment/get-session-details';
  static readonly chargeSessionToken = 'private/api/payment/charge-session-token';
  static readonly updateCandidateBasicPlanTransaction: 'private/api/payment/candidate-basic-plan-transaction';
  static readonly updateCandidateWorkDetails = 'private/api/candidate/updateCandidateWorkDetails';
  static readonly updateCandidateCertificateDetails = 'private/api/candidate/updateCandidateCertificateDetails';
  static readonly updateCandidateEducationDetails = 'private/api/candidate/updateCandidateEducationDetails';
  static readonly sendContactUs = 'private/api/admin/contact-us';
  static readonly sendContactUsPublic = 'public/api/contact-us';
  static readonly sendContactUsRecruiting = 'private/api/admin/contact-us-recruiting';
  static readonly sendContactUsIT = 'private/api/admin/contact-us-IT';
  static readonly sendChangeRoleRequestPublic = 'public/api/change-role-request';
  static readonly newsLetter = 'public/api/news-letter';
  static readonly getUserByRoles = 'private/api/admin/getUserByRoles';
  static readonly getUserByRolesByPage = 'private/api/admin/getUserByRolesByPage';
  static readonly getSalesJobCounts = 'private/api/admin/getSalesJobCounts';
  static readonly disableEnableUser = 'private/api/admin/disableEnableUser';
  static readonly socialMediaRegistration = 'public/api/registration/socialMediaRegistration';
  static readonly createSession = 'private/api/conferencing/createSession';
  static readonly getConference = 'private/api/conferencing/getConference';
  static readonly getMeetings = 'private/api/conferencing/getMeetings';
  static readonly getMutualUpcomingMeeting = 'private/api/conferencing/getMutualUpcomingMeeting';
  static readonly getMutualUpcomingInvites = 'private/api/conferencing/getMutualUpcomingInvites';
  static readonly getMutualLastMeeting = 'private/api/conferencing/getMutualLastMeeting';
  static readonly getFreeTimeSlots = 'private/api/conferencing/getFreeTimeSlots';
  static readonly createMeeting = 'private/api/conferencing/createMeeting';
  static readonly rescheduleMeeting = 'private/api/conferencing/rescheduleMeeting';
  static readonly createPosts = 'private/api/posts/createPosts';
  static readonly getPosts = 'private/api/posts/getPosts';
  static readonly addComment = 'private/api/posts/addComment';
  static readonly addLikes = 'private/api/posts/addLikes';
  static readonly addSharedPosts = 'private/api/posts/addShared';
  static readonly getCandidateJobsByStatus = 'private/api/job/getCandidateJobsByStatus';
  static readonly getCandidateJobs = 'private/api/candidate/getCandidateJobs';
  static readonly applyJob = 'private/api/candidate/applyJob';
  static readonly applyExternalJob = 'private/api/candidate/applyExternalJob';
  static readonly interestedJob = 'private/api/candidate/setInterestedJob';
  static readonly getCandidateJobsAppliedByUserId = 'private/api/job/getCandidateJobsAppliedByUserId';
  static readonly getAcceptRejectEmail = 'private/api/company/getAcceptRejectEmail';
  static readonly createAcceptRejectEmail = 'private/api/company/createAcceptRejectEmail';
  static readonly editAcceptRejectEmail = 'private/api/company/editAcceptRejectEmail';
  static readonly getCandidatePendingJobsAppliedByStatus = 'private/api/job/getCandidatePendingJobsAppliedByStatus';
  static readonly updateCandidateConfirmation = 'private/api/candidate/updateCandidateConfirmation';
  static readonly updateCandidateRejection = 'private/api/candidate/updateCandidateRejection';
  static readonly getAllSystemNotification = 'private/api/notification/allNotifications';
  static readonly updateCandidateOnTheWeb = 'private/api/candidate/update/on-the-web';
  static readonly updateCandidateProfilePictureStatus = 'private/api/candidate/updateProfilePictureStatus';
  static readonly updateCandidateCvStatus = 'private/api/candidate/updateCvStatus';
  static readonly updateCandidatePublicProfileStatus = 'private/api/candidate/updateCandidatePublicProfileStatus';
  static readonly getClientProfileStatus = 'private/api/candidate/profileStatus';
  static readonly getSuggestedAIJobsByJobId = 'private/api/job/getSuggestedAIJobsByJobId';
  static readonly getSuggestedCandidatesByJobId = 'private/api/job/getSuggestedCandidatesByJobId';
  static readonly updateClientPortfolio = 'private/api/candidate/update/portfolio';
  static readonly updateClientVideoPortfolio = 'private/api/candidate/update/video-portfolio';
  static readonly updateCandidateSkills = 'private/api/candidate/update/skills';
  static readonly updateCandidateLanguages = 'private/api/candidate/update/languages';
  static readonly updateClientPayment = 'private/api/candidate/update/payment';
  static readonly updateClientHandicapInfo = 'private/api/candidate/update/handicap-info';
  static readonly updateCandidateAdditionalInfo = 'private/api/candidate/update/additional-info';
  static readonly updateCompanyManagerInfo = 'private/api/company/update/managerInfo';
  static readonly updateCompanyDetailsStep1 = 'private/api/company/update/step-1';
  static readonly updateCompanyGeneralInfo = 'private/api/company/update/generalInfo';
  static readonly updateCompanyBenefits = 'private/api/company/update/benefits';
  static readonly updateCompanyMission = 'private/api/company/update/mission';
  static readonly updateCompanyGallery = 'private/api/company/update/gallery';
  static readonly updateCompanyOnTheWeb = 'private/api/company/update/onTheWeb';
  static readonly storeHiringManager = 'private/api/company/storeHiringManager';
  static readonly getHiringManager = 'private/api/company/getHiringManager';
  static readonly getCandidateMyJobsSelectedByStatus = 'private/api/job/getCandidateMyJobsSelectedByStatus';
  static readonly addNewFollow = 'private/api/candidate/applyFollow';
  static readonly deleteFollow = 'private/api/candidate/applyUnFollow';
  static readonly deleteApplication = 'private/api/candidate/deleteApplication';
  static readonly getJobsFollowedByUserId = 'private/api/job/getJobsFollowedByUserId';
  static readonly getCandidateFollows = 'private/api/job/getCandidateFollows';
  static readonly jobUpdateStep5 = 'private/api/job/update/step-5';
  static readonly getEmployerCandidateJobApplied = 'private/api/job/getEmployerCandidateJobApplied';
  static readonly addSupportComment = 'private/api/job/addSupportComment';
  static readonly getSupportComment = 'private/api/job/getSupportComment';
  static readonly chargeToken: 'private/api/payment/charge-token';
  static readonly getTransactionHistory = 'private/api/payment/transaction-history';
  static readonly getPlanDetails = 'private/api/payment/get-plan-details';
  static readonly getInvoice = 'private/api/payment/get-invoice';
  static readonly getLanguage = 'public/api/language';
  static readonly updateLanguage = 'public/api/update-language';
  static readonly getLanguageByKey = 'public/api/language/languageByKey';
  static readonly storeFavouriteCandidate = 'private/api/company/storeFavCandidate';
  static readonly deleteFavouriteCandidate = 'private/api/company/deleteFavCandidate';
  static readonly deleteAcceptReject = 'private/api/company/deleteAcceptReject';
  static readonly getFavourites = 'private/api/company/getFavourites';
  static readonly getFavouriteCandidatesProfile = 'private/api/company/getFavouriteCandidatesProfile';
  static readonly getNonFavourites = 'private/api/company/getNonFavourites';
  static readonly jobAlerts = 'private/api/job/jobAlerts';
  static readonly getJobAlerts = 'private/api/job/jobAlerts/get';
  static readonly deleteJobAlerts = 'private/api/job/jobAlerts/delete';
  static readonly editJobAlerts = 'private/api/job/jobAlerts/edit';
  static readonly boostJob = 'private/api/job/boostJob';
  static readonly parseCV = 'private/api/file/parseCV';
  static readonly parseCandidateCV = 'private/api/candidate/getParsedCV';
  static readonly recoverJob = 'private/api/job/jobRestore';
  static readonly getAdminJobs = 'private/api/admin/getJobs';
  static readonly getProspectiveJobs = 'public/api/getProspectiveJobs';
  static readonly resetJobDuration = 'private/api/job/jobDurationReset';
  static readonly professions = 'public/api/job/professions';
  static readonly getCandidateNewJobs = 'private/api/candidate/getCandidateNewJobs';
  static readonly getCandidateTopNewJobs = 'private/api/candidate/getCandidateTopNewJobs';
  static readonly getCandidateTopAppliedJobs = 'private/api/job/getCandidateTopJobsApplied';
  static readonly getTopUpcomingConference = 'private/api/conferencing/getTopUpcomingConference';
  static readonly getBookmark = 'private/api/candidate/getBookmark';
  static readonly storeBookmark = 'private/api/candidate/storeBookmark';
  static readonly removeBookmark = 'private/api/candidate/removeBookmark';
  static readonly getCandidateSavedJobs = 'private/api/job/getCandidateSavedJobs';
  static readonly addLoginActivity = 'public/api/login/addLoginActivity';
  static readonly getUserTopLoginActivities = 'private/api/account-settings/getUserTopLoginActivity';
  static readonly verifyLoginActivities = 'private/api/account-settings/verifyLoginActivity';
  static readonly cancelLoginActivities = 'private/api/account-settings/cancelLoginActivity';
  static readonly updateCandidatePersonalInfoSettings = 'private/api/account-settings/update/personal-info-settings';
  static readonly hideJob = 'private/api/candidate/hideJob';
  static readonly getNotificationSettings = 'private/api/account-settings/system-notifications';
  static readonly updateUserSecuritySettings = 'private/api/account-settings/updateUserSecuritySettings';
  static readonly upsertNotificationSettings = 'private/api/account-settings/system-notifications';
  static readonly getEmployerDashboardCount = 'private/api/company/getEmployerDashboardCount';
  static readonly getEmployerDashboardCountByUserId = 'private/api/company/getEmployerDashboardCountByUserId';
  static readonly getEmployeeDesk = 'private/api/company/getEmployeeDesk';
  static readonly sendOTP = 'public/api/otp/sendOTP';
  static readonly verifyOTP = 'public/api/otp/verifyOTP';
  static readonly getEmployeeRating = 'private/api/company/getEmployeeRating';
  static readonly storeEmployeeRating = 'private/api/company/storeEmployeeRating';
  static readonly sendJobRequestToSuggested = `private/api/job/sendJobRequestToSuggested`;
  static readonly getEmployerJobCount = `private/api/job/getEmployerJobCount`;
  static readonly getFAQ = `private/api/faq/getFAQ`;
  static readonly upsertFAQ = `private/api/faq/upsertFAQ`;
  static readonly updateEmployeeHelpDesk = `private/api/company/updateEmployeeHelpDesk`;
  static readonly cancelSubscription = 'private/api/payment/cancelSubscription';
  static readonly updateSubscription = 'private/api/payment/updateSubscription';
  static readonly publishJobFromOpenState = 'private/api/job/publishJobFromOpenState';
  static readonly rejectCandidateForJobSuggestion = 'private/api/job/rejectCandidateForJobSuggestion';
  static readonly getEmployerJobsByStatus = 'private/api/job/getEmployerJobsByStatus';
  static readonly updateCandidateStatus = 'private/api/candidate/updateCandidateStatus';
  static readonly updateCandidateSubStatus = 'private/api/candidate/updateCandidateSubStatus';
  static readonly updateCandidateStatusPublic = 'public/api/public/candidate/updateCandidateStatus';
  static readonly setProfileTutorialCompleted = 'private/api/dashboard/setProfileTutorialCompleted';
  static readonly storeHiringManagerByJobId = 'private/api/company/storeHiringManagerByJobId';
  static readonly updateJobViewCount = 'private/api/job/updateJobViewCount';
  static readonly importJobs = 'private/api/admin/importJobs';
  static readonly importExternalJobs = 'private/api/admin/importExternalJobs';
  static readonly importCompanyJobs = 'private/api/company/importJobs';
  static readonly getPaymentMethod = 'private/api/payment/getPaymentMethod';
  static readonly validatePlan = 'private/api/payment/validatePlan';
  static readonly getCustomerBalance = 'private/api/payment/get-customer-balance';
  static readonly getTalentPool = 'private/api/job/getTalentPool';
  static readonly updateEmailVerify = 'private/api/admin/updateEmailVerify';
  static readonly updateCandidatePublicProfileStatusAdmin = 'private/api/admin/updateCandidatePublicProfileStatus';
  static readonly updateForgotPassword = 'private/api/admin/sendEmail';
  static readonly userLoginById = 'private/api/admin/userLogin';
  static readonly deleteUser = 'private/api/admin/deleteUser';
  static readonly withdrawJob = 'private/api/candidate/withdrawJob';
  static readonly linkedinRegistration = 'public/api/registration/linkedin';
  static readonly getCandidateTopProfessions = 'private/api/admin/getCandidateTopProfessions';
  static readonly getEmployerTopProfessions = 'private/api/admin/getEmployerTopProfessions';
  static readonly getAllJobs = 'public/api/get-all-jobs';
  static readonly getLogs = 'private/api/admin/getLogs';
  static readonly getStoredProcedures = 'private/api/admin/getStoredProcedures';
  static readonly storeAds = 'private/api/ads-manager/storeAds';
  static readonly getStoreAds = 'private/api/ads-manager/getStoreAds';
  static readonly deleteStoreAds = 'private/api/ads-manager/deleteStoreAds';
}

