export const environment = {
  production: false,
  origin: 'http://localhost:3000/',
  originPublic: 'http://localhost:3000/public/',
  originStatic: '',
  imageURL: 'http://localhost:3000',
  stripe_key: 'pk_test_E2fZlOo6MrHVCxf07YRnV3xF',
  frontend: 'http://localhost:4200',
  googleTagManagerId: 'G-86XRZMXWL9',
  elasticSearchUrl: 'https://elasticsearch.smartwork.ch/',
  firebaseAppUrl:
    'https://swiswiss-f2140-default-rtdb.europe-west1.firebasedatabase.app/',
  firebaseKey: 'AIzaSyAUo5y_ssSHKJvMSXNfqbN4J_YSnUcbIjw',
  googleAppId: '115449879945-26vvq8f7d8mhb2aspe4qv91ss0kf59bv.apps.googleusercontent.com',
  linkedinClientId: '77qzazrdmrirz1',
};
// export const environment = {
//   production: false,
//   origin: 'http://13.40.154.15:3000/',
//   originPublic: 'http://13.40.154.15:3000/public/',
//   originStatic: '',
//   imageURL: 'http://13.40.154.15:3000',
//   stripe_key: 'pk_test_E2fZlOo6MrHVCxf07YRnV3xF',
//   frontend: 'http://swiswiss.ch',
//   googleTagManagerId: 'GTM-WHX49GT',
//   elasticSearchUrl: 'http://18.130.185.214/'
// };
