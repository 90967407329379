import {MultiLanguage} from "../../core/models/enum";

export function getLanguageCode(e) {
  switch (e) {
    case "German":
      return MultiLanguage.German;
    case "English":
      return MultiLanguage.English;
    default:
      return MultiLanguage.German;
  }
}
