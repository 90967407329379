import { Injectable } from '@angular/core';
import { ApiConstants } from '../constants/api.constant';
import { catchError, map } from 'rxjs/operators';
import { HttpClientService } from './http-client.service';
import { SupportModel, SupportModelPagination } from '../models/support.model';
import { BehaviorSubject } from 'rxjs';
import { Job } from '../models/job.model';
import { User } from '../models/user';
import { Translation } from '../models/language.model';
import { RequestData } from '../models/request.model';
import { LogsModel } from '../models/logs.model';

@Injectable()
export class AdminService {
  messageSource = new BehaviorSubject('');
  updateActiveJobs = new BehaviorSubject('');

  constructor(private http_client: HttpClientService) {
  }

  setMessageSource(data: any) {
    this.messageSource.next(data);
  }

  setUpdateActiveSource(data: any) {
    this.updateActiveJobs.next(data);
  }

  getSupportTicket(support: SupportModelPagination) {
    return this.http_client.post(`${ApiConstants.getAdminSupportTicket}`, support).pipe(
      map((res: any) => {
        console.log('[getSupportTicket]', res);
        return res;
      }),
      catchError((error: any) => {
        console.error(error);
        throw error;
      }),
    );
  }

  getStoredProcedureLogs(data: RequestData<LogsModel>) {
    return this.http_client.post(`${ApiConstants.getLogs}`, data).pipe(
      map((res: any) => {
        console.log('[getStoredProcedureLogs]', res);
        return res;
      }),
      catchError((error: any) => {
        console.error(error);
        throw error;
      }),
    );
  }

  getSystemLogs(data: RequestData<LogsModel>) {
    return this.http_client.post(`${ApiConstants.getLogs}`, data).pipe(
      map((res: any) => {
        console.log('[getSystemLogs]', res);
        return res;
      }),
      catchError((error: any) => {
        console.error(error);
        throw error;
      }),
    );
  }

  getStoredProcedures() {
    return this.http_client.get(`${ApiConstants.getStoredProcedures}`).pipe(
      map((res: any) => {
        console.log('[getStoredProcedures]', res);
        return res;
      }),
      catchError((error: any) => {
        console.error(error);
        throw error;
      }),
    );
  }

  updateLanguage(data : Translation) {
    return this.http_client.post(ApiConstants.updateLanguageData, data).pipe(
      map((res: any) => {
          return res;
        
      }),
      catchError((error: any) => {
        console.log(error);
        throw error;
      }),
    );

  }

  deleteLanguage(valueId) {
    
    return this.http_client.delete(`${ApiConstants.deleteLanguageData}?valueId=${valueId}`, {
      valueId,
    }).pipe(
      map((res: any) => {
          return res;
        
      }),
      catchError((error: any) => {
        console.log(error);
        throw error;
      }),
    );
  }

  updateProfession(data : Translation) {
    return this.http_client.post(ApiConstants.updateProfession, data).pipe(
      map((res: any) => {
          return res;
        
      }),
      catchError((error: any) => {
        console.log(error);
        throw error;
      }),
    );

  }

  deleteProfession(valueId) {
    return this.http_client.delete(`${ApiConstants.deleteProfession}?valueId=${valueId}`, {
      valueId,
    }).pipe(
      map((res: any) => {
          return res;
        
      }),
      catchError((error: any) => {
        console.log(error);
        throw error;
      }),
    );

  }


  changeSalesStatus(user: User, comment, status: number) {
    return this.http_client.post(`${ApiConstants.setAdminSalesStatus}`, { user, comment, status }).pipe(
      map((res: any) => {
        console.log('[setAdminSalesStatus]', res);
        return res;
      }),
      catchError((error: any) => {
        console.error(error);
        throw error;
      }),
    );
  }

  sendAccidentMail(user_id) {
    return this.http_client.post(`${ApiConstants.sendAccidentEmail}`, { user_id });
  }

  getSupportTicketCount(support: SupportModelPagination) {
    return this.http_client.post(`${ApiConstants.getAdminSupportTicketCount}`, support).pipe(
      map((res: any) => {
        console.log('[getSupportTicket]', res);
        return res;
      }),
      catchError((error: any) => {
        console.error(error);
        throw error;
      }),
    );
  }

  getJobSupportTicket(support: SupportModelPagination) {
    return this.http_client.post(`${ApiConstants.getAdminJobSupportTicket}`, support).pipe(
      map((res: any) => {
        console.log('[getSupportTicket]', res);
        return res;
      }),
      catchError((error: any) => {
        console.error(error);
        throw error;
      }),
    );
  }


  getAllSupportTicket(support: SupportModel) {
    return this.http_client.post(`${ApiConstants.getAllSupportTicket}`, support).pipe(
      map((res: any) => {
        console.log('[getAllSupportTicket]', res);
        return res;
      }),
      catchError((error: any) => {
        console.error(error);
        throw error;
      }),
    );
  }

  updateSupportTicketStatus(support: SupportModel) {
    return this.http_client.post(`${ApiConstants.updateAdminSupportTicketStatus}`, support).pipe(
      map((res: any) => {
        console.log('[getSupportTicket]', res);
        return res;
      }),
      catchError((error: any) => {
        console.error(error);
        throw error;
      }),
    );
  }

  getUserByRoles(userRole: number) {
    return this.http_client.get(`${ApiConstants.getUserByRoles}?userRole=${userRole}`).pipe(
      map((res: any) => {
        console.log('[getUserByRoles]', res);
        return res;
      }),
      catchError((error: any) => {
        console.error(error);
        throw error;
      }),
    );
  }

  getUserByRolesPage(userRole: number) {
    return this.http_client.get(`${ApiConstants.getUserByRolesByPage}?userRole=${userRole}&limit=0&skip=0`).pipe(
      map((res: any) => {
        console.log('[getUserByRoles]', res);
        return res;
      }),
      catchError((error: any) => {
        console.error(error);
        throw error;
      }),
    );
  }

  getSalesJobCount(id: number) {
    return this.http_client.get(`${ApiConstants.getSalesJobCounts}?userId=${id}`).pipe(
      map((res: any) => {
        console.log('[getUserByRoles]', res);
        return res;
      }),
      catchError((error: any) => {
        console.error(error);
        throw error;
      }),
    );
  }

  disableEnableUser(userId: number, IsDisable: boolean) {
    return this.http_client.post(`${ApiConstants.disableEnableUser}`, { userId, IsDisable }).pipe(
      map((res: any) => {
        console.log('[disableEnableUser]', res);
        return res;
      }),
      catchError((error: any) => {
        console.error(error);
        throw error;
      }),
    );
  }

  updateEmailVerify(userId: number, isEmailVerify: boolean) {
    return this.http_client.post(`${ApiConstants.updateEmailVerify}`, { userId, isEmailVerify }).pipe(
      map((res: any) => {
        console.log('[updateEmailVerify]', res);
        return res;
      }),
      catchError((error: any) => {
        console.error(error);
        throw error;
      }),
    );
  }

  getJobs() {
    return this.http_client.get(`${ApiConstants.getAdminJobs}`).pipe(
      map((res: any) => {
        console.log('[getJobs]', res);
        return res;
      }),
      catchError((error: any) => {
        console.error(error);
        throw error;
      }),
    );
  }

  importJobs(job: Job[]) {
    return this.http_client.post(`${ApiConstants.importJobs}`, job).pipe(
      map((res: any) => {
        console.log('[importJobs]', res);
        return res;
      }),
      catchError((error: any) => {
        console.error(error);
        throw error;
      }),
    );
  }

  importExternalJobs(job: Job[]) {
    return this.http_client.post(`${ApiConstants.importExternalJobs}`, job).pipe(
      map((res: any) => {
        console.log('[importJobs]', res);
        return res;
      }),
      catchError((error: any) => {
        console.error(error);
        throw error;
      }),
    );
  }

  updateCandidatePublicProfileStatus(candidateDetails: any) {
    return this.http_client.post(ApiConstants.updateCandidatePublicProfileStatusAdmin, candidateDetails).pipe(
      map((res: any) => {
        console.log('[updateCandidatePublicProfileStatus Response]', res);
        return res;
      }),
      catchError((error: any) => {
        console.error(error);
        throw error;
      }),
    );
  }

  updateForgotPassword(email: string) {
    return this.http_client.post(`${ApiConstants.updateForgotPassword}`, { Email: email }).pipe(
      map((res: any) => {
        console.log('[updateEmailVerify]', res);
        return res;
      }),
      catchError((error: any) => {
        console.error(error);
        throw error;
      }),
    );
  }

  getCandidateTopProfessions() {
    return this.http_client.get(`${ApiConstants.getCandidateTopProfessions}`).pipe(
      map((res: any) => {
        console.log('[getCandidateTopProfessions]', res);
        return res;
      }),
      catchError((error: any) => {
        console.error(error);
        throw error;
      }),
    );
  }

  getEmployerTopProfessions() {
    return this.http_client.get(`${ApiConstants.getEmployerTopProfessions}`).pipe(
      map((res: any) => {
        console.log('[getEmployerTopProfessions]', res);
        return res;
      }),
      catchError((error: any) => {
        console.error(error);
        throw error;
      }),
    );
  }
}

