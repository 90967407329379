export enum UserRole {
  Employer = 1,
  Candidate = 2,
  Admin = 3,
  SalesManager = 4
}

// Deprecated
export enum CandidateStatus {
  Applications = 1,
  Contact = 2,
  Applied = 3,
  Archive = 4,
  Declined = 5,
  Accepted = 6,
  ApplicationsDescription = 'Applications',
  ContactDescription = 'Contact',
  AppliedDescription = 'Applied',
  ArchiveDescription = 'Archive',
  DeclinedDescription = 'Declined',
  AcceptedDescription = 'Accepted',
  FollowedDescription = 'Followed'
}


export enum FileUploadType {
  ProfileImage = 1,
  CandidateProfile = 2,
  MediaImage = 3,
  MediaFile = 4,
  CandidateWorkFiles = 5,
  CandidateCV = 6,
  DiplomaCertificates = 7,
  JobReference = 8,
  CandidateEducation = 9,
  CandidateCertificatesEducation = 10,
  CandidateAddWorkDetails = 11,
  JobCoverImage = 12,
  PostsImage = 13,
  HiringManagerDocuments = 11,
  Portfolio,
  ManagerProfileImage = 14,
  Gallery = 15,
  CandidateVideoPortfolio = 16,
  CompanyCoverImage = 17,
  ApplicationVideo = 18,
  AdImage = 19
}

export enum JobExperienceLevel {
  Junior = 1,
  Professional = 2,
  Senior = 3,
  All = 4,
}

export enum JobStatus {
  Open = 1,
  OpenDescription = 'Open',
  OpenTranslation = 'Employer.OpenJobs',
  Published = 2,
  PublishedDescription = 'Published',
  PublishedTranslation = 'Employer.Active',
  Closed = 3,
  ClosedDescription = 'Closed',
  ClosedTranslation = 'Employer.Closed',
  Draft = 4,
  DraftDescription = 'Draft',
  DraftTranslation = 'Employer.Draft',
  Deleted = 5,
  DeletedDescription = 'Delete',
  DeletedTranslation = 'Employer.Deleted'
}

export enum NotificationType {
  EmployerProfile = 1,
  EmployerProfileDescription = 'EmployerProfile',
  Rating = 2,
  RatingDescription = 'Rating',
  JobPublish = 3,
  JobPublishDescription = 'JobPublish',
  JobEdit = 4,
  JobEditDescription = 'JobEdit',
  AcceptReject = 5,
  AcceptRejectDescription = 'AcceptReject',
  Chat = 6,
  ChatDescription = 'Chat',
  Payment = 7,
  PaymentDescription = 'Payment',
  Post = 8,
  PostDescription = 'Post',
  Video = 9,
  VideoDescription = 'VideoConferencing',
  CandidateProfile = 10,
  CandidateProfileDescription = 'CandidateProfile',
  CandidateApplied = 11,
  CandidateAppliedDescription = 'CandidateApplied',
  CandidateReject = 12,
  CandidateRejectDescription = 'CandidateReject',
  JobStatus = 13,
  JobStatusDescription = 'JobStatus',
  CandidateFollow = 14,
  CandidateFollowDescription = 'CandidateFollow',
  CandidateUnfollow = 15,
  CandidateUnfollowDescription = 'CandidateUnfollow',
  ResetPassword = 16,
  ResetPasswordDescription = 'ResetPassword',
  CandidateAccept = 17,
  CandidateAcceptDescription = 'CandidateAccept',
  LoginActivity = 18,
  LoginActivityDescription = 'LoginActivitylogged'
}

export enum SupportTicketStatus {
  Open = 1,
  OpenDescription = 'Open',
  Close = 2,
  CloseDescription = 'Close',
  Archive = 3,
  ArchiveDescription = 'Archive',
}

export enum SupportCategory {
  Technical = 1,
  Administration,
  JobRecovery,
  ReportError = 5
}

export enum PlanType {
  none = 0,
  ShortTerm = 1,
  ShortTermDescription = 'ShortTerm',
  Basic = 2,
  BasicDescription = 'Basic',
  Advanced = 3,
  AdvancedDescription = 'Advanced',
  Premium = 4,
  PremiumDescription = 'Premium',
  Enterprise = 7, // don't change this enum because this is map to database
  EnterpriseDescription = 'Enterprise',
  CandidateBasic = 5,
  CandidateBasicDescription = 'Basic',
  CandidatePremium = 6,
  CandidatePremiumDescription = 'Premium',
  BoostJob = 8,
  BoostJobDescription = 'Boost Job',
  SocialAdsManager = 9,
  SocialAdsManagerDescription = 'Social Media Manager',
  SocialRecruitingSmall = 10,
  SocialRecruitingSmallDescription = 'Social Recruiting - Small Paket',
  SocialRecruitingMedium = 11,
  SocialRecruitingMediumDescription = 'Social Recruiting - Medium Paket',
  SocialRecruitingLarge = 12,
  SocialRecruitingLargeDescription = 'Social Recruiting Large - Large Paket',
  
}

export enum HiringManagerType {
  Direct = 1,
  DirectDescription = 'Direct',
  PartyPayroll = 2,
  PartyPayrollDescription = '3rd Party Payroll',
  None = 3,
  NoneDescription = 'None'
}

export enum ApplicationType {
  Normal = 1,
  NormalDescription = 'Normal',
  Video = 2,
  VideoDescription = 'Video',
}

export enum LanguagesKey {
  Gender = 'GENDER',
  Title = 'TITLE',
  Education = 'EDUCATION',
  JobType = 'JOB_TYPE',
  CompanyType = 'COMPANY_TYPE',
  Position = 'POSITION',
  ExperienceLevel = 'EXPERIENCE_LEVEL',
  WorkExperienceLevel = 'WORK_EXPERIENCE_LEVEL',
  ExperienceLevelEdv = 'EXPERIENCE_LEVEL_EDV',
  SkillExperienceLevelEdv = 'SKILL_EXPERIENCE_LEVEL_EDV',
  LanguageExperienceLevel = 'LANG_EXPERIENCE_LEVEL',
  ProfileLanguageExperienceLevel = 'PROFILE_LANG_EXPERIENCE_LEVEL',
  Industry = 'INDUSTRY',
  SoftSkills = 'SOFTSKILLS',
  Languages = 'LANGUAGES',
  DrivingLicense = 'DRIVING_LICENSE',
  EdvSkills = 'EDV_SKILLS',
  TattooCategory = 'TATTOO_CATEGORY',
  PiercingCategorie = 'PIERCING_CATEGORY',
  Region = 'REGION',
  WeekDays = 'WEEK_DAYS',
  Marital = 'MARITAL',
  WorkMode = 'WORK_MODE',
  SupportCategory = 'SUPPORT_CATEGORY',
  CompanySize = 'COMPANY_SIZE',
  Origin = 'ORIGIN',
  ResidencePermit = 'RESIDENCE_PERMIT',
  LogsLevels = 'LOGS_LEVELS'
}

export enum MultiLanguage {
  English = 'En',
  German = 'De',
}

export enum JobBoostedResponse {
  AlreadyBoosted,
  Boosted,
  CannotBoost
}

export enum SupportCategories {
  Technical = 1,
  Administration = 2,
  JobRecovery = 3,
  PaymentRecovery = 4
}

export enum JobAlertFrequency {
  Daily = 1,
  Weekly = 2,
  Monthly = 3
}

export enum JobAlertStatus {
  Active = 1,
  Disabled = 0,
}

export enum ConferenceType {
  All = 0,
  Scheduled = 1,
  Today = 2,
  Finish = 3,
}

export enum EmployerJobStatus {
  Applications = 1,
  ApplicationsDescription = 'Applications',
  InProgress = 2,
  InProgressDescription = 'InProgress',
  Offered = 3,
  OfferedDescription = 'Offered',
  Rejected = 4,
  RejectedDescription = 'Rejected',
  Accepted = 5,
  AcceptedDescription = 'Accepted'
}


export enum EmployerJobSubStatus {
  InProgress = 1,
  InProgressDescription = 'Chips.Employer.InProgress',
  InContact = 2,
  InContactDescription = 'Chips.Employer.InContact',
  Interviewed = 3,
  InterviewedDescription = 'Chips.Employer.Interviewed',
  Rejected = 4,
  RejectedDescription = 'Chips.Employer.Rejected',
  Accepted = 5,
  AcceptedDescription = 'Chips.Employer.Accepted',
  ContractRequest = 6,
  ContractRequestDescription = 'Chips.Employer.ContractRequest',
  JobRequest = 7,
  JobRequestDescription = 'Chips.Employer.JobRequest',
  Applicant = 8,
  ApplicantDescription = 'Chips.Employer.Applicant',
  OfferSent = 9,
  OfferSentDescription = 'Chips.Employer.OfferSent',
  ReadyForSearch = 10,
  ReadyForSearchDescription = 'Chips.Employer.ReadyForSearch',
}


export enum CandidateJobStatus {
  Applied = 1,
  AppliedDescription = 'Applied',
  JobOffer = 2,
  JobOfferDescription = 'JobOffer',
  Accepted = 3,
  AcceptedDescription = 'Accepted',
  Rejected = 4,
  RejectedDescription = 'Rejected',
  NoAction = 5,
  NoActionDescription = 'No Action',
  RejectedBySystem = 6,
  RejectedBySystemDescription = 'Rejected By System',
  AcceptedByManaged = 7,
  AcceptedByManagedDescription = 'Accepted By Managed Account',
}

export enum CandidateJobSubStatus {
  InProgress = 1,
  InProgressDescription = 'Chips.Candidate.InProgress',
  InContact = 2,
  InContactDescription = 'Chips.Candidate.InContact',
  Interviewed = 3,
  InterviewedDescription = 'Chips.Candidate.Interviewed',
  Rejected = 4,
  RejectedDescription = 'Chips.Candidate.Rejected',
  Accepted = 5,
  AcceptedDescription = 'Chips.Candidate.Accepted',
  ContractRequest = 6,
  ContractRequestDescription = 'Chips.Candidate.ContractRequest',
  JobRequest = 7,
  JobRequestDescription = 'Chips.Candidate.JobRequest',
  applicant = 8,
  ApplicantDescription = 'Chips.Candidate.Applicant',
  OfferSent = 9,
  OfferSentDescription = 'Chips.Candidate.OfferSent',
  ReadyForSearch = 10,
  ReadyForSearchDescription = 'Chips.Candidate.ReadyForSearch',
  AcceptedByManaged = 11,
  AcceptedByManagedDescription = 'Chips.Candidate.AcceptedManaged',
  IsExternalApplied = 12,
  IsExternalAppliedDescription = 'Chips.Candidate.External',
  ExternalAppliedConfirm = 13,
  ExternalAppliedConfirmDescription = 'Chips.Candidate.External',
  IsExternalAcceptConfirmed = 14,
  IsExternalAcceptConfirmedDescription = 'Chips.Candidate.External',
}


export enum MatchingRate {
  NotMatch = 'No Match',
  LowMatch = 'Low Match',
  FairMatch = 'Fair Match',
  GoodMatch = 'Good Match',
  GreatMatch = 'Great Match',
  External = 'Chips.Candidate.External',
}

export enum WorkMode {
  Permanent = 1,
  Temporary = 2
}

export enum PlanPrice {
  Basic = 69,
  Advanced = 99,
  Premium = 149,
  Enterprise = 399,
  BoostJob = 49.95,
  SocialAdsManager = 149.95
}

export type ChatState = 'all' | 'archived' | 'star';
export type SortOrder = 'asc' | 'desc';
