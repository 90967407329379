export class EncryptUtility {
  static encode_btoa(str:string): string
  {
   return btoa(unescape(encodeURIComponent(str)));
  }
  static decode_atob(str:string): string
  {
    return decodeURIComponent(escape(window.atob(str)));
  }
}
