import { Injectable } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap';
import { BehaviorSubject, Subject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { ApiConstants } from '../constants/api.constant';
import { Password } from '../models/password.model';
import { HttpClientService } from './http-client.service';
import { Title } from '@angular/platform-browser';
import { Login } from '../models/login.model';
import { ConfirmationModalComponent } from '../../shared/components/confirmation-modal/confirmation-modal.component';
import { User } from '../models/user';
import { LanguagesKey, MultiLanguage, UserRole } from '../models/enum';
import { SupportModel } from '../models/support.model';
import { ContactUs, ContactUsPagination } from '../models/contactUs.model';
import { Translation } from '../models/language.model';
import { EncryptUtility } from '../../shared/utilities/encrypt.utility';
import { TranslateService } from '@ngx-translate/core';
import { LoginActivity } from '../models/loginActivity.model';
import { HttpClient } from '@angular/common/http';
import { ChangeRole } from '../models/ChangeRole.model';
import { DescriptionModalComponent } from '../../shared/components/description-modal/description-modal.component';

// import { User, Password, Login } from '../models/login.model';
@Injectable()
export class AuthService {
  jwt_token = 'auth-token';
  refresh_token = 'refresh-token';
  rememberUser = 'remember-user';
  authUserInfo = 'user-data';
  userInfo = 'user-data-auth';
  language = 'language-data';
  professions = 'profession-data';

  public socket: any;
  onLangChange = new Subject();
  messageSource = new BehaviorSubject('');


  constructor(
    private http_client: HttpClientService,
    private modalService: BsModalService,
    private title: Title,
    public translate: TranslateService,
    private httpClient: HttpClient,
  ) {
  }

  setMessageSource(data: any) {
    this.messageSource.next(data);
  }

  getAuthToken() {
    return localStorage.getItem(this.jwt_token) || null;
  }

  setAuhToken(token: string) {
    localStorage.setItem(this.jwt_token, token);
  }

  setRefreshToken(refreshToken: string) {
    localStorage.setItem(this.refresh_token, refreshToken);
  }

  setLanguage(language: string) {
    localStorage.setItem(this.language, EncryptUtility.encode_btoa(language));
  }

  isLanguageSet() {
    return localStorage.getItem(this.language);
  }

  getLanguage(key: LanguagesKey, langCode: MultiLanguage) {
   
    const languages = JSON.parse(EncryptUtility.decode_atob(localStorage.getItem(this.language)));
    const filterLanguage = languages.filter(lang => lang.Key === key);
    let returnLanguages: Translation[] = [];
    filterLanguage.forEach((lang: Translation) => {
      if (key === LanguagesKey.Industry) {
        if (lang.Parent != 0) {
          returnLanguages.push({
            De: '',
            En: '',
            Id: lang.Id, Key: '',
            Sort: lang.Sort,
            Code: lang.Code,
            Value: lang[langCode],
            Mapping: lang.Mapping,
            Parent: lang.Parent,
            ParentCategory: !!lang.Parent && lang.Parent != 0 && key === LanguagesKey.Industry ? this.getParentIndustry(lang, languages, langCode) : '',
          });
        }
      } else {
        returnLanguages.push({
          De: '',
          En: '',
          Id: lang.Id, Key: '',
          Sort: lang.Sort,
          Code: lang.Code,
          Value: lang[langCode],
          Mapping: lang.Mapping,
          Parent: lang.Parent,
          ParentCategory: '',
        });
      }

    });

    return returnLanguages.sort(function(a, b) {
      return b.Sort - a.Sort;
    });
  }

  getParentIndustry(currentLanguage: Translation, allLanguages: Translation[], langCode: string) {
    const lang = allLanguages.filter((record) => record.Key === LanguagesKey.Industry && +record.Code === +currentLanguage.Parent);
    if (lang.length) {
      return lang[0][langCode];
    } else {
      return '';
    }
  }

  setTitle(title: string) {
    this.title.setTitle('SWI' + title);
  }


  setUserInfoAuth(userInfo: any) {
    localStorage.setItem(
      this.userInfo,
      userInfo ? EncryptUtility.encode_btoa(JSON.stringify(userInfo)) : '',
    );
  }

  getUserInfoAuth(): User {
    if (!!localStorage.getItem(this.userInfo)) {
      return JSON.parse(EncryptUtility.decode_atob(localStorage.getItem(this.userInfo)));
    } else {
      return null;
    }
  }

  getCurrentUserRoleName() {
    const user: User = this.getUserInfoAuth();
    switch (user.UserType) {
      case UserRole.Candidate:
        return 'candidate';
      case UserRole.Employer:
        return 'employer';
      case UserRole.Admin:
        return 'admin';
      case UserRole.SalesManager:
        return 'sales';
      default:
        return '';
    }
  }

  login(loginModel: Login) {
    return this.http_client.post(ApiConstants.login, loginModel).pipe(
      map((res: any) => {
        console.log('res login', res);
        if (res.data.token && res.data.user.TwoFactorEnabled === 0) {
          this.setAuhToken(res.data.token);
          this.setRefreshToken(res.data.token);
          this.setUserInfoAuth(res.data.user);
          return res;
        } else {
          return res;
        }
      }),
      catchError((error: any) => {
        console.log(error);
        throw error;
      }),
    );
  }

  addLoginActivity(loginActivity: LoginActivity) {
    return this.http_client.post(ApiConstants.addLoginActivity, loginActivity).pipe(
      map((res: any) => {
        console.log('res addLoginActivity', res);
        return res;
      }),
      catchError((error: any) => {
        console.log(error);
        throw error;
      }),
    );
  }

  getLanguages() {
    return this.http_client.get(ApiConstants.getLanguage).pipe(
      map((res: any) => {
        console.log('[getLanguages]', res);
        this.setLanguage(JSON.stringify(res.data));
        return res;
      }),
      catchError((error: any) => {
        console.error(error);
        throw error;
      }),
    );
  }

  getDecyphredKey(key: string, url: string) {
    return this.http_client.get(`${ApiConstants.getDecypheredKey}?key=${key}&link=${url}`).pipe(
      map((res: any) => {
        console.log('[getDecyphredKey]', res); 
        return res;
      }),
      catchError((error: any) => {
        console.error(error);
        throw error;
      }),
    );
  }

  getLanguagesByKey(key: string) {
    return this.http_client.get(`${ApiConstants.getLanguageByKey}?key=${key}`).pipe(
      map((res: any) => {
        console.log('[getLanguagesByKey]', res);
        this.setLanguage(JSON.stringify(res.data));
        return res;
      }),
      catchError((error: any) => {
        console.error(error);
        throw error;
      }),
    );
  }

  getProfessions() {
    return this.http_client.get(ApiConstants.professions).pipe(
      map((res: any) => {
        console.log('[getProfessions]', res);
        this.setProfessions(JSON.stringify(res.data));
        return res;
      }),
      catchError((error: any) => {
        console.error(error);
        throw error;
      }),
    );
  }

  isProfessionsSet() {
    return localStorage.getItem(this.professions);
  }

  setProfessions(professions: string) {
    localStorage.setItem(this.professions, EncryptUtility.encode_btoa(professions));
  }

  getProfessionsByLang(lang): Array<{ Id: number, Profession: string }> {
    const prof: Array<{ Id: number, De: string, En: string, GermanSynonyms: string, EnglishSynonyms: string }> = JSON.parse(EncryptUtility.decode_atob(localStorage.getItem(this.professions)));

    let returnProfessions: Array<{ Id: number, Profession: string, Synonyms: string }> = [];
    const langSynonyms = lang === MultiLanguage.English ? 'EnglishSynonyms' : 'GermanSynonyms';
    prof.forEach((profession) => {
      returnProfessions.push({
        Id: profession.Id,
        Profession: profession[lang],
        Synonyms: profession[langSynonyms],
      });
    });

    return returnProfessions;
  }

  getProfessionsById(lang, Id: string): string {
    const prof: Array<{ Id: number, De: string, En: string }> = JSON.parse(EncryptUtility.decode_atob(localStorage.getItem(this.professions)));

    let returnProfession: { Id: number, Profession: string };
    let Ids = [];
    if (Id) {
      if (Id.includes(',')) {
        Ids = this.stringToArr(Id);
      } else {
        Ids = [Id];
      }

      for (let profId of Ids) {
        for (let profession of prof) {
          if (profession.Id == profId) {
            returnProfession = {
              Id: profession.Id,
              Profession: profession[lang],
            };
            break;
          }
        }
      }
    }


    return returnProfession ? returnProfession.Profession : '';
  }

  getProfessionById(lang, Id): { Id: number, Profession: string } {
    const prof: Array<{ Id: number, De: string, En: string }> = JSON.parse(EncryptUtility.decode_atob(localStorage.getItem(this.professions)));

    let returnProfession: { Id: number, Profession: string };
    for (let profession of prof) {
      if (profession.Id == Id) {
        returnProfession = {
          Id: profession.Id,
          Profession: profession[lang],
        };
        break;
      }
    }


    return returnProfession;
  }

  getProfessionsByNames(lang, Id): { Id: number, Profession: string } {
    const prof: Array<{ Id: number, De: string, En: string }> = JSON.parse(EncryptUtility.decode_atob(localStorage.getItem(this.professions)));

    let returnProfession: any = [];
    const Ids = this.stringToArr(Id);
    for (let profId of Ids) {
      for (let profession of prof) {
        if (profession.De == profId || profession.En == profId) {
          returnProfession.push(profession[lang]);
        }
      }
    }

    return returnProfession.toString();
  }

  stringToArr(Ids) {
    return Ids.split(',').map(x => x.trim());
  };

  getProfessionsByIds(lang, Ids): string {
    const prof: Array<{ Id: number, De: string, En: string }> = JSON.parse(EncryptUtility.decode_atob(localStorage.getItem(this.professions)));
    let professions = Ids ? Ids.split(',') : [];
    let returnProfession = [];
    professions.forEach(id => {
      let profession = prof.find(x => x.Id == id);
      if (profession && profession[lang]) {
        returnProfession.push(profession[lang]);
      }
    });
    return returnProfession.join(', ');
  }

  getProfessionsByValue(lang, value): { Id: number, Profession: string } {
    const prof: Array<{ Id: number, De: string, En: string }> = JSON.parse(EncryptUtility.decode_atob(localStorage.getItem(this.professions)));

    let returnProfession: { Id: number, Profession: string };
    for (let profession of prof) {
      if (profession.En == value) {
        returnProfession = {
          Id: profession.Id,
          Profession: profession[lang],
        };
      }
    }

    return returnProfession;
  }

  socialMediaRegistration(loginModel: Login) {
    return this.http_client.post(ApiConstants.socialMediaRegistration, loginModel).pipe(
      map((res: any) => {
        console.log('[socialMediaRegistration]', res);
        return res;
      }),
      catchError((error: any) => {
        console.error(error);
        throw error;
      }),
    );
  }


  getUserByUserId(id: number) {
    return this.http_client
      .get(
        `${ApiConstants.getUserByUserId}?id=${id}`,
      )
      .pipe(
        map((res: any) => {
          console.log('[getUserDetails]', res);
          return res;
        }),
        catchError((error: any) => {
          console.error(error);
          throw error;
        }),
      );
  }

  changePassword(password: Password) {
    return this.http_client.post(ApiConstants.changePassword, password).pipe(
      map((res: any) => {
        console.log('[changePassword]', res);
        return res;
      }),
      catchError((error: any) => {
        console.error(error);
        throw error;
      }),
    );
  }

  sendResetEmailPassword(loginModel: Login) {
    return this.http_client.post(ApiConstants.sendEmail, loginModel).pipe(
      map((res: any) => {
        console.log('[sendResetEmailPassword]', res);
        return res;
      }),
      catchError((error: any) => {
        console.error(error);
        throw error;
      }),
    );
  }

  verifyHash(hash: string) {
    return this.http_client.get(`${ApiConstants.verifyHash}?forgotHash=${hash}`).pipe(
      map((res: any) => {
        console.log('[verifyHash]', res);
        return res;
      }),
      catchError((error: any) => {
        console.error(error);
        throw error;
      }),
    );
  }

  resetPassword(forgotPassword: any) {
    return this.http_client.post(`${ApiConstants.resetPassword}`, forgotPassword).pipe(
      map((res: any) => {
        console.log('[resetPassword]', res);
        return res;
      }),
      catchError((error: any) => {
        console.error(error);
        throw error;
      }),
    );
  }

  logout() {
    this.setAuhToken('');
    this.setUserInfoAuth('');
    this.setRefreshToken('');
  }

  openConfimationModal() {
    const modal = this.modalService.show(ConfirmationModalComponent, {
      backdrop: 'static',
    });
    return <ConfirmationModalComponent>modal.content;
  }

  openDescriptionModal() {
    const modal = this.modalService.show(DescriptionModalComponent);
    
    return <DescriptionModalComponent>modal.content;
  }

  verifyEmail(token: string, email: string) {
    return this.http_client
      .post(
        `${ApiConstants.emailVerify}`,
        JSON.stringify({
          Token: token,
          Email: email,
        }),
      )
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: any) => {
          console.error(error);
          throw error;
        }),
      );
  }

  createSupportTicket(support: SupportModel) {
    return this.http_client.post(`${ApiConstants.createSupport}`, support).pipe(
      map((res: any) => {
        console.log('[createSupportTicket]', res);
        return res;
      }),
      catchError((error: any) => {
        console.error(error);
        throw error;
      }),
    );
  }

  createSupportTicketForJobRecovery(support: SupportModel) {
    return this.http_client.post(`${ApiConstants.createSupportForJobRecovery}`, support).pipe(
      map((res: any) => {
        console.log('[createSupportTicket]', res);
        return res;
      }),
      catchError((error: any) => {
        console.error(error);
        throw error;
      }),
    );
  }

  createSupportTicketForPaymentRecovery(support: SupportModel) {
    return this.http_client.post(`${ApiConstants.createSupportForPaymentRecovery}`, support).pipe(
      map((res: any) => {
        console.log('[createSupportTicket]', res);
        return res;
      }),
      catchError((error: any) => {
        console.error(error);
        throw error;
      }),
    );
  }

  getSupportTicket(support: SupportModel) {
    return this.http_client.post(`${ApiConstants.getSupportTicket}`, support).pipe(
      map((res: any) => {
        console.log('[getSupportTicket]', res);
        return res;
      }),
      catchError((error: any) => {
        console.error(error);
        throw error;
      }),
    );
  }

  updateSupportTicketStatus(support: SupportModel) {
    return this.http_client.post(`${ApiConstants.updateSupportTicketStatus}`, support).pipe(
      map((res: any) => {
        console.log('[updateSupportTicketStatus]', res);
        return res;
      }),
      catchError((error: any) => {
        console.error(error);
        throw error;
      }),
    );
  }

  deleteSupportTicket(supportId) {
    return this.http_client
      .delete(`${ApiConstants.deleteSupportTicket}?supportId=${supportId}`, {
        supportId,
      })
      .pipe(
        map((res: any) => {
          console.log('[deleteSupportTicket Response]', res);
          return res;
        }),
        catchError((error: any) => {
          console.error(error);
          throw error;
        }),
      );
  }

  getAllSupportTicketCount(support: SupportModel) {
    return this.http_client.post(`${ApiConstants.getAllSupportTicketCount}`, support).pipe(
      map((res: any) => {
        console.log('[getAllSupportTicketCount]', res);
        return res;
      }),
      catchError((error: any) => {
        console.error(error);
        throw error;
      }),
    );
  }

  getSupportTicketCount(support: SupportModel) {
    return this.http_client.post(`${ApiConstants.getSupportTicketCount}`, support).pipe(
      map((res: any) => {
        console.log('[getSupportTicketCount]', res);
        return res;
      }),
      catchError((error: any) => {
        console.error(error);
        throw error;
      }),
    );
  }

  sendContactUs(support: ContactUs) {
    return this.http_client.post(`${ApiConstants.sendContactUsPublic}`, support).pipe(
      map((res: any) => {
        console.log('[getSupportTicket]', res);
        return res;
      }),
      catchError((error: any) => {
        console.error(error);
        throw error;
      }),
    );
  }

  sendContactUsRecruiting(support: ContactUs) {
    return this.http_client.post(`${ApiConstants.sendContactUsRecruiting}`, support).pipe(
      map((res: any) => {
        console.log('[getSupportTicket]', res);
        return res;
      }),
      catchError((error: any) => {
        console.error(error);
        throw error;
      }),
    );
  }

  sendContactUsIT(support: ContactUs) {
    return this.http_client.post(`${ApiConstants.sendContactUsIT}`, support).pipe(
      map((res: any) => {
        console.log('[getSupportTicket]', res);
        return res;
      }),
      catchError((error: any) => {
        console.error(error);
        throw error;
      }),
    );
  }

  getContactUs(support: ContactUsPagination) {
    return this.http_client.post(`${ApiConstants.sendContactUs}/get`, support).pipe(
      map((res: any) => {
        console.log('[getContactUsDetails]', res);
        return res;
      }),
      catchError((error: any) => {
        console.error(error);
        throw error;
      }),
    );
  }

  sendContactUsReply(support: ContactUs) {
    return this.http_client.post(`${ApiConstants.sendContactUs}/reply`, support).pipe(
      map((res: any) => {
        console.log('[getSupportTicket]', res);
        return res;
      }),
      catchError((error: any) => {
        console.error(error);
        throw error;
      }),
    );
  }

  getContactUsComments(id: number) {
    return this.http_client.get(`${ApiConstants.sendContactUs}/comments/${id}`).pipe(
      map((res: any) => {
        console.log('[getSupportTicket]', res);
        return res;
      }),
      catchError((error: any) => {
        console.error(error);
        throw error;
      }),
    );
  }

  sendNewsLetter(support: ContactUs) {
    return this.http_client.post(`${ApiConstants.newsLetter}`, support).pipe(
      map((res: any) => {
        console.log('[sendNewsLetter]', res);
        return res;
      }),
      catchError((error: any) => {
        console.error(error);
        throw error;
      }),
    );
  }

  sendOTP(countryCode: string, phoneNumber: string, emailAddress: string) {
    return this.http_client.post(`${ApiConstants.sendOTP}`, { countryCode, phoneNumber, emailAddress }).pipe(
      map((res: any) => {
        console.log('[sendOTP]', res);
        return res;
      }),
      catchError((error: any) => {
        console.error(error);
        throw error;
      }),
    );
  }

  verifyOTP(authyId: string, token: string) {
    return this.http_client.post(`${ApiConstants.verifyOTP}`, { authyId, token }).pipe(
      map((res: any) => {
        console.log('[sendOTP]', res);
        return res;
      }),
      catchError((error: any) => {
        console.error(error);
        throw error;
      }),
    );
  }

  userLoginById(userId: number) {
    return this.http_client.get(`${ApiConstants.userLoginById}/${userId}`).pipe(
      map((res: any) => {
        console.log('[userLogin Response]', res);
        if (res.data.token) {
          this.setAuhToken(res.data.token);
          this.setRefreshToken(res.data.token);
          this.setUserInfoAuth(res.data.user);
          return res;
        } else {
          return res;
        }
      }),
      catchError((error: any) => {
        console.error(error);
        throw error;
      }),
    );
  }

  loadIndustry(locale: string) {
    return this.httpClient.get(`assets/data/industries_${locale}.json`).pipe(
      map((res: any) => {
        return res;
      }),
      catchError((error: any) => {
        console.error(error);
        throw error;
      }),
    );
  }

  deleteUser(userId: number) {
    return this.http_client.post(`${ApiConstants.deleteUser}`, { userId }).pipe(
      map((res: any) => {
        console.log('[userLogin Response]', res);
        return res;
      }),
      catchError((error: any) => {
        console.error(error);
        throw error;
      }),
    );
  }

  signupWithLinkedin(token: string, userRole: UserRole) {
    return this.http_client.get(`${ApiConstants.linkedinRegistration}?token=${token}&userRole=${userRole}`).pipe(
      map((res: any) => {
        console.log('[loginWithLinkedin Response]', res);
        return res;
      }),
      catchError((error: any) => {
        console.error(error);
        throw error;
      }),
    );
  }

  sendChangeRoleRequest(data: ChangeRole) {
    return this.http_client.post(`${ApiConstants.sendChangeRoleRequestPublic}`, data).pipe(
      map((res: any) => {
        console.log('[sendChangeRoleRequestPublic]', res);
        return res;
      }),
      catchError((error: any) => {
        console.error(error);
        throw error;
      }),
    );
  }
}
