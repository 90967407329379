import { Component, OnInit } from '@angular/core';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { NavigationEnd, Router } from '@angular/router';
import { SpinnerOverlayService } from './core/services/spinner-overlay.service';
import localeDE from '@angular/common/locales/de';
import localeEn from '@angular/common/locales/en';
import { registerLocaleData } from '@angular/common';
import { getLanguageCode } from './shared/helper/language.helper';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  loading: boolean = false;
  hidden: boolean = false;
  cookiebot: HTMLScriptElement;
  cookieDeclaration: HTMLScriptElement;
  skipLinkPath = '';
  

  constructor(
    private spinnerService: SpinnerOverlayService,
    private gtmService: GoogleTagManagerService,
    private translate: TranslateService,
    private router: Router,
  ) {
    registerLocaleData(localeDE, 'de');
    registerLocaleData(localeEn, 'en');
    this.translate.onLangChange.subscribe((event: Event) => {
      if (!!this.cookiebot) {
        document.getElementsByTagName('head')[0].removeChild(this.cookiebot);
      }
      if (!!this.cookieDeclaration) {
        document.getElementsByTagName('head')[0].removeChild(this.cookieDeclaration);
      }
      this.cookiebot = document.createElement('script');
      this.cookiebot.type = 'text/javascript';
      this.cookiebot.id = 'cookiebot';
      this.cookiebot.setAttribute('data-cbid', '8fbdf1c4-387b-4031-bfa8-eeea40d8c44b');
      if (!!localStorage.getItem('language')) {
        this.cookiebot.setAttribute('data-culture', getLanguageCode(localStorage.getItem('language')));
      } else {
        this.cookiebot.setAttribute('data-culture', 'DE');
      }
      this.cookiebot.async = true;
      this.cookiebot.src = 'https://consent.cookiebot.com/uc.js';
      document.getElementsByTagName('head')[0].appendChild(this.cookiebot);

      this.cookieDeclaration = document.createElement('script');
      this.cookieDeclaration.type = 'text/javascript';
      this.cookieDeclaration.id = 'CookieDeclaration';
      this.cookieDeclaration.async = true;
      this.cookieDeclaration.src = 'https://consent.cookiebot.com/8fbdf1c4-387b-4031-bfa8-eeea40d8c44b/cd.js';
      document.getElementsByTagName('head')[0].appendChild(this.cookieDeclaration);

      var cookiebotDialog = document.getElementById('CybotCookiebotDialog');

      if (!!cookiebotDialog) {
        document.location.reload();
      }
      
    });

    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.skipLinkPath = `${this.router.url.replace('#main-content', '')}#main-content`;
      }
    });
  }


  async ngOnInit() {
    this.spinnerService.initialize();


    this.router.events.forEach((item) => {
      if (item instanceof NavigationEnd) {
        const gtmTag = {
          event: 'page',
          pageName: item.url,
        };
        this.gtmService.pushTag(gtmTag);
      }
    });
  }
}

// function getElementById(arg0: string) {
//   throw new Error('Function not implemented.');
// }

