import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { BsModalRef } from 'ngx-bootstrap';


@Component({
  selector: 'app-description-modal',
  templateUrl: './description-modal.component.html',
  styleUrls: ['./description-modal.component.css']
})
export class DescriptionModalComponent implements OnInit {
  bodyHtml: any;
  active: boolean;
  body: string;
  title: string;
  onClose: Subject<boolean>;

  constructor(
    private sanitizer: DomSanitizer,
    public modalRef: BsModalRef
  ) { }

  ngOnInit() {
    this.onClose = new Subject();
  }

  public showDescriptionModal(title: string, body: string): void 
  {
    this.active = true;
    this.title = title;
    this.bodyHtml = this.sanitizer.bypassSecurityTrustHtml(body);
  }

  public onCancel(): void {
    this.active = false;
    this.modalRef.hide();
    this.onClose.next(false);
  }

}
