import {Injectable} from '@angular/core';
import {ToastrService} from "ngx-toastr";
import {ApiConstants} from "../constants/api.constant";
import {catchError, map} from "rxjs/operators";
import {HttpClientService} from "./http-client.service";
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class NotificationService {
  toastrConfig = {
    timeOut: 10000,
    progressBar: true,
    maxOpened: 1
  }

  constructor(public toastr: ToastrService,
              private http_client: HttpClientService,
              public translate: TranslateService) {
  }

  success(messageCode, title = 'Success') {
    const header = this.translate.instant('Notification.Title.'+title);
    const message = this.translate.instant('Notification.Message.'+messageCode);
    this.toastr.clear();
    this.toastr.success(
      message.includes('Notification.Message.')?messageCode:message,
      header.includes('Notification.Title.')?title:header,
      this.toastrConfig
    );
  }

  error(messageCode = '000', title = 'Error') {
    const header = this.translate.instant('Notification.Title.'+title);
    const message = this.translate.instant('Notification.Message.'+messageCode);
    this.toastr.clear();
    this.toastr.error(
      message.includes('Notification.Message.')?messageCode:message,
      header.includes('Notification.Title.')?title:header,
      this.toastrConfig
    );
  }

  info(messageCode, title = 'Information') {
    const header = this.translate.instant('Notification.Title.'+title);
    const message = this.translate.instant('Notification.Message.'+messageCode);
    this.toastr.clear();
    this.toastr.info(
      message.includes('Notification.Message.')?messageCode:message,
      header.includes('Notification.Title.')?title:header,
      this.toastrConfig
    );
  }

  warn(messageCode, title = 'Warning') {
    const header = this.translate.instant('Notification.Title.'+title);
    const message = this.translate.instant('Notification.Message.'+messageCode);
    this.toastr.clear();
    this.toastr.warning(
      message.includes('Notification.Message.')?messageCode:message,
      header.includes('Notification.Title.')?title:header,
      this.toastrConfig
    );
  }

  getSystemNotification() {
    return this.http_client.get(`${ApiConstants.getSystemNotification}`).pipe(
      map((res: any) => {
        console.log("[getSystemNotification Response]", res);
        return res;
      }),
      catchError((error: any) => {
        console.error(error);
        throw error;
      })
    );
  }

  setSystemNotificationAsRead(ids: string) {
    return this.http_client.post(`${ApiConstants.setSystemNotificationAsRead}`, {ids}).pipe(
      map((res: any) => {
        console.log("[setSystemNotificationAsRead Response]", res);
        return res;
      }),
      catchError((error: any) => {
        console.error(error);
        throw error;
      })
    );
  }

  getAllSystemNotification() {
    return this.http_client.get(`${ApiConstants.getAllSystemNotification}`).pipe(
      map((res: any) => {
        console.log("[getSystemNotification Response]", res);
        return res;
      }),
      catchError((error: any) => {
        console.error(error);
        throw error;
      })
    );
  }
}
