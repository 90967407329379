import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StyleServiceService {

  private stylesMap: Map<string, Node> = new Map();
  private scriptMap: Map<string, Node> = new Map();
  private host: Node;

  constructor() {
    this.host = document.head;
  }

  addStyle(key: string, style: string): void {
    const styleEl = this.createStyleNode(style);
    this.stylesMap.set(key, styleEl);
    this.host.appendChild(styleEl);
  }

  removeStyle(key: string): void {
    const styleEl = this.stylesMap.get(key);
    if (styleEl) {
      this.stylesMap.delete(key);
      this.host.removeChild(styleEl);
    }
  }

  public addScript(key: string, style: string) {
    const styleEl = this.createScriptNode(style);
    this.scriptMap.set(key, styleEl);
    this.host.appendChild(styleEl);
  }

  removeScript(key: string): void {
    const styleEl = this.scriptMap.get(key);
    if (styleEl) {
      this.scriptMap.delete(key);
      this.host.removeChild(styleEl);
    }
  }

  private createStyleNode(content: string): Node {
    const styleEl = document.createElement('link');
    styleEl.href = content;
    styleEl.rel = "stylesheet";
    return styleEl;
  }

  private createScriptNode(content: string): Node {
    const styleEl = document.createElement('script');
    styleEl.src = content;
    styleEl.type = 'text/javascript';
    styleEl.async = false;
    return styleEl;
  }
}
