// Core Module
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule, Title } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
// Main Component
import { AppComponent } from './app.component';
// Component Module
import { AgmCoreModule } from '@agm/core';
import { PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

import { CoreModule } from './core/core.module';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { ModalModule, TooltipModule } from 'ngx-bootstrap';
import { CustomInterceptor } from './core/interceptor/custom-interceptor';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { PageNotFoundComponent } from './shared/components/page-not-found/page-not-found.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AuthServiceConfig, FacebookLoginProvider, GoogleLoginProvider, SocialLoginModule } from 'angularx-social-login';
import { environment } from '../environments/environment';
import { Constants } from './shared/Constants';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { ToastrModule } from 'ngx-toastr';
import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { SpinnerOverlayComponent } from './shared/components/spinner-overlay/spinner-overlay.component';
import { ShepherdService } from 'angular-shepherd';


const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
};

export function provideConfig() {
  return new AuthServiceConfig([
    {
      id: GoogleLoginProvider.PROVIDER_ID,
      provider: new GoogleLoginProvider(
        environment.googleAppId, {
          scope: 'email',
          plugin_name: 'SWI',
        } as any),
    },
    {
      id: FacebookLoginProvider.PROVIDER_ID,
      provider: new FacebookLoginProvider('Facebook-App-Id'),
    },
  ]);
}

@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent,
    SpinnerOverlayComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    HttpClientModule,
    CoreModule,
    OverlayModule,
    ModalModule.forRoot(),
    AppRoutingModule,
    AgmCoreModule.forRoot({ apiKey: Constants.GoogleMapKey }),
    BrowserAnimationsModule,
    TooltipModule.forRoot(),
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    DeviceDetectorModule.forRoot(),
    ToastrModule.forRoot(),
    SocialLoginModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CustomInterceptor,
      multi: true,
    },
    Title,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    { provide: MatDialogRef, useValue: {} },
    { provide: MAT_DIALOG_DATA, useValue: [] },
    {
      provide: AuthServiceConfig,
      useFactory: provideConfig,
    },
    { provide: 'googleTagManagerId', useValue: environment.googleTagManagerId },
    ShepherdService,
  ],
  bootstrap: [AppComponent],
  exports: [SpinnerOverlayComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  entryComponents: [SpinnerOverlayComponent],
})
export class AppModule {
  constructor(
    private router: Router,
    private titleService: Title
  ) {
    this.router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        const title = 'Smart Work';
        this.titleService.setTitle(title);
      }
    });
  }
}

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}
