import { Component, OnInit, Input } from '@angular/core';
import { delay } from 'rxjs/operators';
import { SpinnerOverlayService } from '../../../core/services/spinner-overlay.service';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.css']
})
export class SpinnerComponent implements OnInit {

  @Input() message = '';
  loading: boolean = false;

  constructor(
    private spinnerService: SpinnerOverlayService
  ) { }

  ngOnInit() {
    this.listenToLoading();
  }

  listenToLoading(): void {
    this.spinnerService.loadingSub
      .pipe(delay(0)) // This prevents a ExpressionChangedAfterItHasBeenCheckedError for subsequent requests
      .subscribe((loading) => {
        this.loading = loading;
        console.log("loading is now :", this.loading);


      });
  }
}