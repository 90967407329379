import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { ComponentRef, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SpinnerOverlayComponent } from '../../shared/components/spinner-overlay/spinner-overlay.component';

@Injectable({
  providedIn: 'root',
})
export class SpinnerOverlayService {
  loadingSub: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  /**
   * Contains in-progress loading requests
   */
  loadingMap: Map<string, boolean> = new Map<string, boolean>();

  private overlayRef: OverlayRef = null;

  component: ComponentRef<SpinnerOverlayComponent>;
  requestCount = 0;

  constructor(private overlay: Overlay) {
  }

  public initialize() {
  }


  // public start(message = '') {
  //
  //   // const html = document.getElementsByTagName('html')[0]
  //   // if (!this.overlayRef) {
  //   //   this.overlayRef = this.overlay.create();
  //   // }
  //
  //   // // Create ComponentPortal that can be attached to a PortalHost
  //   // const spinnerOverlayPortal = new ComponentPortal(SpinnerOverlayComponent);
  //   //   setTimeout(() => {
  //   //     const shouldSet = this.overlayRef && !this.overlayRef.hasAttached();
  //
  //   //         if (shouldSet)
  //   //     {
  //   //     this.component = this.overlayRef.attach(spinnerOverlayPortal); // Attach ComponentPortal to PortalHost
  //   //     }
  //   //     // document.documentElement.style.overflow = 'hidden';
  //   //     html.classList.add('lock-scroll');
  //   //     // TODO: set message
  //   //     // component.instance.message = message;
  //   //   });
  //
  //   //   setTimeout(() => {
  //   //     this.complete();
  //   //   },3000
  //   //   );
  //
  //
  //   //   // Returns an OverlayRef (which is a PortalHost)
  //
  // }

  // public complete() {
  //   // const html = document.getElementsByTagName('html')[0]
  //   // if (!!this.overlayRef) {
  //   //   this.component = null;
  //   //   this.overlayRef.detach();
  //   //   html.classList.remove('lock-scroll')
  //   // }
  // }

  setLoading(loading: boolean, url: string): void {
    if (!url) {
      throw new Error('The request URL must be provided to the LoadingService.setLoading function');
    }
    if (loading) {
      this.requestCount++;
    } else {
      this.requestCount--;
    }
    if (this.requestCount > 0) {
      this.load();
    } else {
      this.finish();
    }
  }

  public load(message = '') {

    const html = document.getElementsByTagName('html')[0];
    if (!this.overlayRef) {
      this.overlayRef = this.overlay.create();
    }

    // Create ComponentPortal that can be attached to a PortalHost
    const spinnerOverlayPortal = new ComponentPortal(SpinnerOverlayComponent);
    const shouldSet = this.overlayRef && !this.overlayRef.hasAttached();

    if (shouldSet) {

      this.component = this.overlayRef.attach(spinnerOverlayPortal); // Attach ComponentPortal to PortalHost
    }
    html.classList.add('lock-scroll');
    // TODO: set message
    // component.instance.message = message;
    // });

  }

  public finish() {
    const html = document.getElementsByTagName('html')[0];
    if (!!this.overlayRef) {
      this.component = null;
      this.overlayRef.detach();
      html.classList.remove('lock-scroll');
    }
  }
}
