export const httpResponseTypes = {
  BLOB: 'blob',
  JSON: 'json',
  TEXT: 'text',
  ARRAYBUFFER: 'arraybuffer',
};

export const httpObserves = {
  RESPONSE: 'response',
  EVENT: 'event',
  BODY: 'body',
};

export class Constants {
  static readonly PasswordPattern = /((?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!=@#$%^&*(),.?"":{}|<>-]).{8,50})$/;

  static readonly EmailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;

  static readonly AlphaNumericPattern = '[a-zA-Z][a-zA-Z ]+$';

  static readonly AlphabeticPattern = '/^[A-Za-z ]+$/';

  static readonly InputPattern = '^[A-Za-z0-9 ._-]+(?:[ ._-][A-Za-z0-9]+)*$';

  static readonly AlphaPunctuation = /^[a-zA-Z0-9 .!?"-]+$/;

  static readonly HtmlPattern = '<[^>]*>';

  static readonly MarketplaceSearchBoxPattern = /^[ ]+|[ ]+$/g;

  static readonly MultipleEmailPattern = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*([,]||\s||[,]\s*\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*)*$/;

  // tslint:disable-next-line:max-line-length
  static readonly URLPattern = /^(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/;
  // static readonly URLPattern = '/^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/';

  // Registration Success Messages
  static readonly RegistrationSuccess =
    'You are successfully registered and Verification link has been sent to your email address.';

  static readonly LoginAttempts = 3;

  static readonly English = 'English';
  static readonly German = 'German';
  static readonly CalendarTheme = 'theme-blue';
  static readonly TreeviewClass = 'btn-outline-primary';
  static readonly DateFormat = 'MM/DD/YYYY';

  static readonly DOCExtension = 'doc';
  static readonly DOCXExtension = 'docx';
  static readonly DOCMExtension = 'docm';
  static readonly PDFExtension = 'pdf';
  static readonly JPGExtension = 'jpg';
  static readonly JPEGExtension = 'jpeg';
  static readonly PNGExtension = 'png';
  static readonly WEBPExtension = 'webp';
  static readonly MP4Extension = 'mp4';
  static readonly WEBMExtension = 'webm';
  static readonly MOVExtension = 'mov';
  static readonly MaxFileSize = 536870912;
  static readonly DefaultProvider = 1;
  static readonly isValidZip = /(^\d{5}$)|(^\d{5}-\d{4}$)/;
  static readonly AlphabetPattern = '^[a-zA-Z]+$';
  static readonly NumericPattern = /^\d{10}$/;

  static readonly ImageBaseURL = 'http://localhost:4000';
  static readonly GoogleMapApi = 'https://www.google.com/maps/embed/v1/place';
  static readonly GoogleMapKey = 'AIzaSyCuy6RU4zfY65AZy6qIzsV2iJvR4QGzBVM';
  static readonly GoogleReCaptcha = '6Ld3ziIaAAAAAL_jSNmo8weRqqFVXKk8HGXR0SwC';
  static readonly TinyCloud = 's9xu10nde9d975qebpyeuacnazisvghr23xkx5zjq5zvg11d';

  static readonly ItemsPerPage = 30;
  static readonly CurrentPage = 1;
  static readonly JobListItemsPerPage = 6;
  static readonly JobsPerPage = 30;

  static readonly vatPercentage = 7.7;


  static readonly charLimit = 100;

  static readonly EmployeeTutorialKey = 'EMPLOYEE_TUTORIAL_ENABLED';
  static readonly EmployeePostJobTutorialKey = 'EMPLOYEE_POST_JOB_TUTORIAL_ENABLED';
  static readonly MyJobsTutorialKey = 'MY_JOB_TUTORIALS_KEY';
  static readonly EmployeeAcceptRejectJobTutorialKey = 'EMPLOYEE_ACCEPT_REJECT_JOB_ALERT_TUTORIAL_ENABLED';

  static readonly AcceptedRejectedJobsPage = '/auth/candidate/my-jobs/accepted-rejected';
  static readonly AppliedJobsPage = '/auth/candidate/my-jobs/applied';
  static readonly ContactedJobsPage = '/auth/candidate/my-jobs/contacted';
  static readonly FollowedCompanyJobsPage = '/auth/candidate/my-jobs/followed';
  static readonly SavedJobsPage = '/auth/candidate/my-jobs/saved';

  static readonly ApplicationsStatus = 'applications';
  static readonly SuggestedTalentStatus = 'suggested-talent';
  static readonly InProgressStatus = 'in-progress';
  static readonly OfferedStatus = 'offered';
  static readonly RejectedStatus = 'rejected';


  static readonly facebook = 'Facebook';
  static readonly facebookLogo = 'facebook.svg';
  static readonly linkedin = 'LinkedIn';
  static readonly linkedinLogo = 'linkedin.svg';
  static readonly twitter = 'Twitter';
  static readonly twitterLogo = 'twitter.svg';
  static readonly xing = 'Xing';
  static readonly xingLogo = 'xing.svg';
  static readonly github = 'GitHub';
  static readonly githubLogo = 'github.svg';
  static readonly medium = 'Medium';
  static readonly mediumLogo = 'medium.svg';
  static readonly behance = 'Behance';
  static readonly behanceLogo = 'behance-white.svg';
  static readonly dribble = 'Dribbble';
  static readonly dribbleLogo = 'dribbble.svg';
  static readonly pinterest = 'Pinterest';
  static readonly pinterestLogo = 'pinterest.svg';
  static readonly flickr = 'Flickr';
  static readonly flickrLogo = 'flickr.svg';
  static readonly fiveHundredPx = '500px';
  static readonly fiveHundredPxLogo = '500px.svg';
  static readonly vimeo = 'Vimeo';
  static readonly vimeoLogo = 'vimeo.svg';
  static readonly youtube = 'Youtube';
  static readonly youtubeLogo = 'youtube.svg';
  static readonly instagram = 'Instagram';
  static readonly instagramLogo = 'instagram.svg';
  static readonly TERMS_OF_USE_PDF_URL_GERMAN = 'https://swi.s3.us-east-2.amazonaws.com/pdf/AGB.pdf';
  static readonly TERMS_OF_USE_PDF_URL_EN = 'https://swi.s3.us-east-2.amazonaws.com/pdf/GTC.pdf';
  
  static readonly TwoFactorAuthentication = '2fa';
  static readonly LoginActivity = 'la';

  static readonly DirectHiringMaxFilesCount = 5;
  static readonly DescriptionMaxLength = 3500;
  static readonly EmailMaxLength = 250;
}

