import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  isRefreshingToken: boolean;
  tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor(
    private authService: AuthService
  ) {
    this.isRefreshingToken = false;
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    // add authorization header with jwt token if available

    return next
      .handle(this.addToken(request, this.authService.getAuthToken()))
      .pipe(
        map((event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            // do stuff with response if you want

            return event;
          }
        }),
        catchError((err: any): Observable<any> => {
          const error = err.error;
          return throwError(error);
        }),
      );
  }

  handle401Error(error) {
    if (
      error &&
      error.status === 401 &&
      error.error &&
      error.error.error === 'invalid_grant'
    ) {
      // If we get a 400 and the error message is 'invalid_grant', the token is no longer valid so logout.
      return this.logoutUser(error);
    }
    return throwError(error.status.toString());
  }

  logoutUser(error) {
    this.authService.logout();
    return throwError(error);
  }

  addToken(req: HttpRequest<any>, token: string): HttpRequest<any> {
    // Adding token in request header if token is available
    if (token) {
      return req.clone({ setHeaders: { Authorization: 'Bearer ' + token } });
    } else {
      return req;
    }
  }
}
