import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot,} from "@angular/router";
import {Observable} from "rxjs";

import {AuthService} from "../core/services/auth.service";

@Injectable({
  providedIn: "root",
})
export class NoAuthGuard implements CanActivate {
  redirectURL: string;

  constructor(private router: Router, private authService: AuthService) {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    this.redirectURL = urlParams.get('redirect_url');
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | Observable<boolean> | Promise<boolean> {
    const token = this.authService.getAuthToken();
    if (token) {
      if (!!this.redirectURL) {
        this.router.navigate([`/auth/${this.authService.getCurrentUserRoleName()}/${this.redirectURL}`]);
      } else {
        this.router.navigate([`/auth/${this.authService.getCurrentUserRoleName()}/dashboard`]);
      }
      return false;
    } else {
      return true;
    }
  }
}
