import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthService } from './services/auth.service';
import { HttpClientService } from './services/http-client.service';
import { NotificationService } from './services/notification.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor } from './interceptor/token.interceptor';
import { AdminService } from './services/admin.service';
import { StyleServiceService } from './services/style-service.service';
import { RouterModule } from '@angular/router';
import { SpinnerComponent } from '../shared/components/spinner/spinner.component';


@NgModule({
  declarations: [
    SpinnerComponent,
  ],
  imports: [
    CommonModule,
  ],
  exports: [SpinnerComponent],
  providers: [
    RouterModule,
    AuthService,
    HttpClientService,
    NotificationService,
    AdminService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    StyleServiceService
  ],
})
export class CoreModule {
}
